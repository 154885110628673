import React from 'react';
import { Link } from 'react-router-dom';
import home from '../../images/INICIO.jpeg';

export const Inicio = () => {
  return (
    <div className="inicio">
      <div className="image-container">
        <img src={home} alt="home" className="home-image" />
        <div className="button-container">
          <Link to='/login'>
            <button className="styled-button">Iniciar Sesión</button>
          </Link>

          {/* <Link to='/register'>
            <button className="">Registrarse</button>
          </Link> */}

        </div>
      </div>
    </div>
  );
};
