import React, { useContext, useState } from "react";
import { DataContext } from "../../context/Dataprovider";
import { ProductoItem } from "./ProductoItem";

export const ProductosLista = () => {
  const value = useContext(DataContext);
  const [productos] = value.productos || []; // Asegúrate de que productos sea un array
  const [categorias] = value.categorias || []; // Asegúrate de que categorias sea un array

  // Estado para la categoría seleccionada
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Todas");

  // Estado para la página actual
  const [currentPage, setCurrentPage] = useState(1);

  // Cantidad de productos por página
  const productsPerPage = 25;

  // Cantidad máxima de páginas que se muestran a la vez
  const maxPageNumbersToShow = 5;

  // Calcular los índices de los productos a mostrar en la página actual
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  // Filtrar productos según la categoría seleccionada
  const productosFiltrados = categoriaSeleccionada === "Todas" 
    ? productos 
    : productos.filter(producto => producto.category === categoriaSeleccionada);

  // Asegúrate de que productosFiltrados sea un array antes de utilizar slice
  const currentProducts = Array.isArray(productosFiltrados) 
    ? productosFiltrados.slice(indexOfFirstProduct, indexOfLastProduct) 
    : [];

  // Calcular el número total de páginas
  const totalPages = Math.ceil(productosFiltrados.length / productsPerPage);

  // Calcular el bloque de páginas que se muestra
  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

  // Funciones para cambiar de página
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Función para cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Función para manejar la selección de categorías
  const handleCategoriaChange = (e) => {
    setCategoriaSeleccionada(e.target.value);
    setCurrentPage(1); // Reiniciar a la primera página cuando se cambia la categoría
  };

  return (
    <>
      <h1 className="title">PRODUCTOS</h1>

      {/* Contenedor del selector de categorías */}
      <div className="filter-container">
        <label htmlFor="categoria" className="filter-label">Filtrar por categoría:</label>
        <select 
          id="categoria" 
          onChange={handleCategoriaChange} 
          value={categoriaSeleccionada} 
          className="filter-select"
        >
          <option value="Todas">Todas</option>
          {categorias.map((categoria, index) => (
            <option key={index} value={categoria}>{categoria}</option>
          ))}
        </select>
      </div>

      <div className="productos">
        {currentProducts.map((producto) => (
          <ProductoItem
            key={producto.id}
            id={producto.id}
            title={producto.title}
            price={producto.price}
            image={producto.image}
            category={producto.category}
            internalcode={producto.internalcode}
            itemcode={producto.itemcode}
            prov={producto.prov}
          />
        ))}
      </div>

      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Anterior
        </button>

        {/* Mostrar números de página limitados */}
        {startPage > 1 && (
          <>
            <button onClick={() => goToPage(1)}>1</button>
            {startPage > 2 && <span>...</span>}
          </>
        )}

        {[...Array(endPage - startPage + 1)].map((_, index) => (
          <button
            key={startPage + index}
            className={currentPage === startPage + index ? "active" : ""}
            onClick={() => goToPage(startPage + index)}
          >
            {startPage + index}
          </button>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button onClick={() => goToPage(totalPages)}>{totalPages}</button>
          </>
        )}

        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </button>
      </div>
    </>
  );
};
