import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../context/Dataprovider";

export const ProductoItem = ({
  id,
  title,
  price,
  image,
  category,
  itemcode,
  internalcode,
  prov,
}) => {
  const value = useContext(DataContext);
  const addCarrito = value.addCarrito;
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const [isSmallImage, setIsSmallImage] = useState(false); // Estado para verificar si es imagen pequeña

  // Formatear el precio para que se muestre en pesos mexicanos
  const precioFormateado = price.toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  // Función para abrir el modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Verificar si la imagen tiene un tamaño de 200x200
  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      if (img.width === 200 && img.height === 200) {
        setIsSmallImage(true); // Es una imagen pequeña
      } else {
        setIsSmallImage(false); // Imagen de buen tamaño
      }
    };
  }, [image]);

  return (
    <div className="producto">
      <a href="#" onClick={openModal}>
        <div className="producto_img">
          <img src={image} alt={title} />
        </div>
      </a>
      <div className="producto_footer">
        <h1>{title}</h1>
        <p>{category}</p>
        <p className="price">{precioFormateado} MXN</p>
        <p>{itemcode}</p>
      </div>
      <div className="bottom">
        <button
          className="btn"
          onClick={() => {
            addCarrito(id);
            alert("Producto añadido al carrito exitosamente");
          }}
        >
          Añadir al carrito
        </button>
      </div>

      {/* Modal para mostrar la imagen ampliada */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            {/* Si la imagen es pequeña (200x200), se amplía */}
            <img
              src={image}
              alt={title}
              className="modal-img"
              style={isSmallImage ? { width: "500px", height: "500px" } : {}}
            />
          </div>
        </div>
      )}
    </div>
  );
};
