import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Para redireccionar

const API_URL = process.env.REACT_APP_API_URL + '/login.php'; // Asegúrate de que la URL esté bien configurada

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [cliente, setCliente] = useState(''); // Nuevo estado para guardar el nombre del cliente
    const navigate = useNavigate(); // Hook para redireccionar

    // Verificar si hay un cliente autenticado al cargar el componente
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            setCliente(user.name); // Asumimos que el campo 'name' contiene el nombre del cliente
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(API_URL, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.status === 'success') {
                // Guardar los datos del usuario en localStorage
                localStorage.setItem('user', JSON.stringify(response.data.user));
                setCliente(response.data.user.name); // Guardar el nombre del cliente
                alert('Inicio de sesión exitoso');
                // Redirigir al usuario a la página de productos u otra
                navigate('/productos');
            } else {
                // Mostrar mensaje de error si la respuesta es error
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error al conectar con el servidor');
        }
    };

    const handleLogout = () => {
        // Eliminar datos de usuario del localStorage
        localStorage.removeItem('user');
        setCliente(''); // Limpiar el nombre del cliente del estado
        alert('Sesión cerrada');
        // Redirigir al usuario a la página de login
        navigate('/login');
    };

    return (
        <div className="login">
            <h2>Iniciar Sesión</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {/* Mostrar mensaje de bienvenida si el cliente está autenticado */}
            {cliente ? (
                <div>
                    <h3>Bienvenido, {cliente}!</h3>
                    <button onClick={handleLogout}>Cerrar Sesión</button>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Contraseña:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Iniciar Sesión</button>
                </form>
            )}
        </div>
    );
};
