import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../context/Dataprovider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../images/LogoCandy2.jpg";

export const Carrito = () => {
  const value = useContext(DataContext);
  const [menu, setMenu] = value.menu;
  const [carrito, setCarrito] = value.carrito;
  const [total] = value.total;

  const [folio, setFolio] = useState(null);
  const [cliente, setCliente] = useState("");
  const [correo, setCorreo] = useState(""); // Nuevo estado para el correo


  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setCliente(user.name); 
      setCorreo(user.email); 
      
    }
  }, []);

  // Obtener el folio desde el backend para usar el valor actualizado.
  const obtenerFolioActualizado = async () => {
    try {
      const response = await fetch(
        "https://impulsoemprendedorcandy.com/folios.php"
      );
      const data = await response.json();
      if (data.status === "success") {
        return data.current_folio;
      } else {
        console.error("Error al obtener el folio:", data.message);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const tooglefalse = () => {
    setMenu(false);
  };

  const show1 = menu ? "carritos show" : "carrito";
  const show2 = menu ? "carrito show" : "carrito";

  const resta = (id) => {
    carrito.forEach((item) => {
      if (item.id === id) {
        item.cantidad === 1 ? (item.cantidad = 1) : (item.cantidad -= 1);
      }
      setCarrito([...carrito]);
    });
  };

  const suma = (id) => {
    const nuevoCarrito = carrito.map((item) => {
      if (item.id === id) {
        return { ...item, cantidad: item.cantidad + 1 };
      }
      return item;
    });
    setCarrito(nuevoCarrito);
  };
  

  const removeProducto = (id) => {
    if (window.confirm("¿Quieres eliminar este producto?")) {
      carrito.forEach((item, index) => {
        if (item.id === id) {
          carrito.splice(index, 1);
        }
      });
      setCarrito([...carrito]);
    }
  };

  const generarYEnviarPDF = async () => {
    // Obtener el folio más reciente y actualizarlo en el backend antes de generar el PDF
    await incrementarFolio(); // Incrementar el folio aquí

    // Re-obtener el folio actualizado para usarlo en el PDF
    const nuevoFolio = await obtenerFolioActualizado();

    const doc = new jsPDF();
    const doc2 = new jsPDF();

    doc.addImage(logo, "JPG", 10, 10, 50, 20);
    doc2.addImage(logo, "JPG", 10, 10, 50, 20);

    doc.setFontSize(22);
    doc.text("Comprobante de Cotización", 70, 30);
    doc2.setFontSize(22);
    doc2.text("Comprobante de Cotización con Código Interno", 20, 70);

    doc.setFontSize(12);
    doc.text(`Cliente: ${cliente}`, 20, 50);
    doc.text("Fecha: " + new Date().toLocaleDateString(), 20, 70);

    doc2.setFontSize(12);
    doc2.text(`Cliente: ${cliente}`, 20, 50);doc2.setFontSize(12);
    doc2.text(`Correo: ${correo}`, 20, 60); // Agregar correo
    doc2.text("Fecha: " + new Date().toLocaleDateString(), 20, 70);


    // Colocar el folio actualizado en el PDF
    const folioText = `Folio: ${nuevoFolio}`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getTextWidth(folioText);
    doc.text(folioText, pageWidth - textWidth - 20, 20);
    doc2.text(folioText, pageWidth - textWidth - 20, 20);

    const columns = ["Producto", "Precio", "Cantidad", "Total"];
    const rows = carrito.map((item) => {
      const price = parseFloat(item.price);
      return [
        item.title,
        `$${price.toFixed(2)}`,
        item.cantidad,
        `$${(price * item.cantidad).toFixed(2)}`,
      ];
    });

    doc.autoTable({
      startY: 80,
      head: [columns],
      body: rows,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
    });

    const totalRow = ["Total", "", "", `$${Math.round(total)}`];
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [[]],
      body: [totalRow],
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
    });

    const columnsExtra = [
      "Producto",
      "Código Interno",
      "Proveedor",
      "Precio",
      "Cantidad",
      "Total",
    ];
    const rowsExtra = carrito.map((item) => {
      const price = parseFloat(item.price);
      return [
        item.title,
        item.internalcode || "N/A",
        item.provider || "N/A",
        `$${price.toFixed(2)}`,
        item.cantidad,
        `$${(price * item.cantidad).toFixed(2)}`,
      ];
    });

    doc2.autoTable({
      startY: 80,
      head: [columnsExtra],
      body: rowsExtra,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
    });

    const totalRowExtra = ["Total", "", "", `$${Math.round(total)}`];
    doc2.autoTable({
      startY: doc2.lastAutoTable.finalY + 10,
      head: [[]],
      body: [totalRowExtra],
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
    });

    const terms = [
      "Términos y condiciones de la cotización:",
      "1. Tiempo de entrega estimado de 4 a 5 semanas.",
      "2. Los precios están sujetos a cambios sin previo aviso, pero tales cambios no afectarán",
      "a los pedidos ya aceptados.",
      "3. La cotización tiene una vigencia de 15 días hábiles desde su fecha de emisión.",
      "Transcurrido este plazo, sin que la Cotización haya sido aceptada, es necesario",
      "solicitar una nueva Cotización.",
      "",
      "Correo Miriam – emprendedores@candymart.com.mx",
      "Teléfono fijo – 614-390-05-35",
      "Vía WhatsApp – 614-607-12-10",
    ];

    let y = doc.lastAutoTable.finalY + 20;
    const pageHeight = doc.internal.pageSize.height;
    terms.forEach((line) => {
      if (y + 10 > pageHeight) {
        doc.addPage();
        y = 20;
      }
      doc.text(line, 20, y);
      y += 10;
    });

    let y2 = doc2.lastAutoTable.finalY + 20;
    terms.forEach((line) => {
      doc2.text(line, 20, y2);
      y2 += 10;
    });

    doc.save("comprobante_cotizacion.pdf");

    const pdfBlob = doc2.output("blob");
    await enviarCorreoConPDF(pdfBlob, "cotizacion_con_codigo.pdf");
  };

  // Función para incrementar el folio
  const incrementarFolio = async () => {
    try {
      const response = await fetch(
        "https://impulsoemprendedorcandy.com/actualizar_folio.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ increment: 1 }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        console.log("Folio actualizado exitosamente");
      } else {
        console.error("Error al actualizar el folio:", data.message);
      }
    } catch (error) {
      console.error("Error en la solicitud de actualización del folio:", error);
    }
  };

  const enviarCorreoConPDF = (pdfBlob, nombreArchivo = "cotizacion.pdf") => {
    const formData = new FormData();
    formData.append("pdf", pdfBlob, nombreArchivo);

    fetch("https://impulsoemprendedorcandy.com/mail.php", {
      method: "POST",
      body: formData,
      headers: {
        From: "emprendedores@candymart.com.mx",
        To: "emprendedores@candymart.com.mx",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Correo enviado con éxito");
        } else {
          alert("Error al enviar el correo");
        }
      })
      .catch((error) => {
        console.error("Error al enviar el correo:", error);
      });
  };

  return (
    <div className={show1}>
      <div className={show2}>
        <div className="carrito__close" onClick={tooglefalse}>
          <box-icon name="x"></box-icon>
        </div>
        <h2>Su carrito</h2>
        <div className="carrito__center">
          {carrito.length === 0 ? (
            <h2 style={{ textAlign: "center", fontSize: "3rem" }}>
              Carrito Vacio
            </h2>
          ) : (
            <>
              {carrito.map((producto) => (
                <div className="carrito__item" key={producto.id}>
                  <img src={producto.image} alt={producto.title} />
                  <div>
                    <h3>{producto.title}</h3>
                    <p className="price">${producto.price}</p>
                  </div>
                  <div>
                    <box-icon
                      name="up-arrow"
                      type="solid"
                      onClick={() => suma(producto.id)}
                    ></box-icon>
                    <p className="cantidad">{producto.cantidad}</p>
                    <box-icon
                      name="down-arrow"
                      type="solid"
                      onClick={() => resta(producto.id)}
                    ></box-icon>
                  </div>
                  <div className="remove__item" onClick={() => removeProducto(producto.id)}>
                    <box-icon name="trash"></box-icon>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="carrito__footer">
          <h3>Total: ${Math.round(total)}</h3>
          <button className="btn" onClick={generarYEnviarPDF}>
            Generar y Enviar Cotización
          </button>
        </div>
      </div>
    </div>
  );
};
